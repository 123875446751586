import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import ApplicationState from "../../../store/appStateInterface";
import {
  HelpCenterCard,
  HelpCenterContent,
} from "../../../store/helpcenter/types";
import { AutoComplete } from "./styled";

const SearchBar: React.FunctionComponent = () => {
  const { data: helpCenter } = useSelector(
    (state: ApplicationState) => state.helpCenter
  );
  const [content, setContent] = useState<HelpCenterContent[]>([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (helpCenter.length > 0) {
      const loop = (list: HelpCenterCard[]) =>
        list.reduce((acc, val) => {
          if (val.content) acc.content?.push(...val.content);
          if (val.cards) acc.content?.push(...(loop(val.cards).content || []));
          return acc;
        });
      const teste = loop(JSON.parse(JSON.stringify(helpCenter)));

      setContent(teste.content || []);
    }
  }, [helpCenter]);

  const getHTML = (cont: HelpCenterContent) => {
    const html = cont.html
      .replace(/(<([^>]+)>)/gi, "")
      .toLocaleLowerCase()
      .replace(search.toLocaleLowerCase(), `<b>${search}</b>`);
    return html.substring(
      html.toLowerCase().indexOf(search.toLowerCase()) - 50,
      html.toLowerCase().indexOf(search.toLowerCase()) + 50
    );
  };
  const filteredContent = content.filter(
    (cont) => cont.html.indexOf(search) > -1 || cont.title.indexOf(search) > -1
  );
  return (
    <>
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Como podemos ajudar?"
          aria-label="busca"
          aria-describedby="busca"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <InputGroup.Text id="busca">
          <i className="mdi mdi-magnify" />
        </InputGroup.Text>
        {search && (
          <AutoComplete className="autocomplete">
            {filteredContent.length > 0 ? (
              filteredContent.map((cont) => (
                <li key={cont.slug}>
                  <a href={`/${cont.path}`}>
                    <h5>{cont.title}</h5>
                    <p dangerouslySetInnerHTML={{ __html: getHTML(cont) }} />
                  </a>
                </li>
              ))
            ) : (
              <li>
                <h5>Nenhum resultado encontrado</h5>
              </li>
            )}
          </AutoComplete>
        )}
      </InputGroup>
    </>
  );
};

export default SearchBar;

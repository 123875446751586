import React from "react";
import { Container, Nav } from "react-bootstrap";
import { HelpCenterCard } from "../../../store/helpcenter/types";
import { Card } from "./styled";

interface CardContainerProps {
  helpCenter: HelpCenterCard[];
}

const CardContainer: React.FunctionComponent<CardContainerProps> = ({
  helpCenter = [],
}: CardContainerProps) => {
  const path = window.location.pathname.substring(
    1,
    window.location.pathname.length
  );

  return (
    <Container className="card-container">
      {helpCenter.map((card) => (
        <Nav.Link
          href={`${path}/${card.slug}`}
          key={card.slug}
          disabled={!card.cards && !card.content}
        >
          <Card className="card">
            {card.icon.startsWith("mdi") ? (
              <i className={`mdi ${card.icon}`} />
            ) : (
              <img src={card.icon} alt={card.title} />
            )}

            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </Card>
        </Nav.Link>
      ))}
    </Container>
  );
};

export default CardContainer;

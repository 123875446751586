import React, { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "material-design-icons/iconfont/material-icons.css";
import "@mdi/font/css/materialdesignicons.min.css";

import { useDispatch, useSelector } from "react-redux";

import { AppContainer } from "./components/styled";
import CardContainer from "./components/CardContainer";
import ContentContainer from "./components/ContentContainer";
import SearchBar from "./components/SearchBar";
import Logo from "../../assets/logo.png";
import ApplicationState from "../../store/appStateInterface";
import { setSelectedByPathname } from "../../store/helpcenter/actions";

const App: React.FunctionComponent = () => {
  const {
    data: helpCenter,
    selectedCard,
    loading,
  } = useSelector((state: ApplicationState) => state.helpCenter);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSelectedByPathname(window.location.pathname));
  }, []);

  return (
    <AppContainer className="app-container" urlImg={Logo}>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/" />
          {helpCenter.map((card) => (
            <Nav.Link
              key={card.slug}
              href={`/${card.slug}`}
              disabled={!card.cards && !card.content}
            >
              {card.title}
            </Nav.Link>
          ))}
        </Container>
      </Navbar>
      <Container>
        <h1>Help Marketplace Web Prêmios</h1>
        <p>
          Explore as funcionalidades, tire suas dúvidas e acesse a documentação
          técnica das APIS CloudLoyalty.
        </p>
        <SearchBar />
      </Container>
      {loading ? (
        <h1>LOADING</h1>
      ) : (
        <>
          {selectedCard && selectedCard.content ? (
            <ContentContainer parentCard={selectedCard} />
          ) : (
            <CardContainer helpCenter={selectedCard?.cards || helpCenter} />
          )}
        </>
      )}
    </AppContainer>
  );
};

export default App;

import { Reducer } from "redux";
import { HelpCenterState, HelpCenterTypes, HelpCenterCard } from "./types";

const INITIAL_STATE: HelpCenterState = {
  data: [],
  hasData: false,
  error: false,
  loading: false,
};

const reducer: Reducer<HelpCenterState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HelpCenterTypes.SET_SELECTED: {
      return { ...state, loading: true };
    }
    case HelpCenterTypes.SET_SELECTED_SUCCESS: {
      let selected: HelpCenterCard = {
        cards: action.payload.data || state.data,
        // cards: state.data,
        description: "",
        icon: "",
        title: "",
        slug: "",
      };
      let selContent;
      const slugList = action.payload.pathname.split("/");
      slugList.forEach((slug: string) => {
        if (slug) {
          if (selected.cards) {
            const found = selected.cards.find((card) => card.slug === slug);
            if (found) selected = found;
          } else {
            selContent = slug;
          }
        }
      });

      return {
        ...state,
        data: action.payload.data || state.data,
        selectedCard: selected,
        selectedContent: selContent,
        loading: false,
        hasData: true,
      };
    }

    default:
      return state;
  }
};

export default reducer;

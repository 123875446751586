import { action, Action } from "typesafe-actions";
import { HelpCenterCard, HelpCenterTypes } from "./types";

export const setSelectedByPathname = (pathname: string): Action =>
  action(HelpCenterTypes.SET_SELECTED, { pathname });

export const setSelectedByPathnameSuccess = (
  pathname: string,
  data?: HelpCenterCard[]
): Action => action(HelpCenterTypes.SET_SELECTED_SUCCESS, { pathname, data });

export const setSelectedByPathnameFailure = (): Action =>
  action(HelpCenterTypes.SET_SELECTED_FAILURE);

/**
 * Action types
 */
export enum HelpCenterTypes {
  SET_SELECTED = "@help/SET_SELECTED",
  SET_SELECTED_SUCCESS = "@help/SET_SELECTED_SUCCESS",
  SET_SELECTED_FAILURE = "@help/SET_SELECTED_FAILURE",
}

/**
 * State type
 */
export interface HelpCenterState {
  readonly data: HelpCenterCard[];
  readonly selectedCard?: HelpCenterCard;
  readonly selectedContent?: string;
  readonly hasData: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
// Avos
//  - titulo
//  - icon
//  - slug
// descrição
// sidebar

// Acessar slug e sidebar false
// exibe cards
// Filho

// titulo
// icon
// slug
// descrição
// sidebar
export interface HelpCenterCard {
  title: string;
  description: string;
  icon: string;
  slug: string;
  cards?: HelpCenterCard[];
  content?: HelpCenterContent[];
  group?: string;
}

export interface HelpCenterContent {
  slug: string;
  title: string;
  html: string;
  path?: string;
}

export interface Theme {
  buttonColor: string;
  buttonText: string;
  primaryText: string;
  secondaryText: string;
  backgroundColor: string;
  menuColor: string;
  menuScrolledColor: string;
  menuTitleColor: string;
}

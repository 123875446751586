import styled from "styled-components";

export const AppContainer = styled.div<{ urlImg: string }>`
  .App {
    text-align: center;
  }

  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .navbar-brand {
    width: 140px;
    height: 100px;
    background-image: url(${(props) => props.urlImg});
    background-position: center;
    background-size: 265px;
    background-repeat: no-repeat;
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

    @media (max-width: 1400px) {
      grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    }

    grid-gap: 30px;
  }

  .nav-link {
    color: #3f3f40;
    padding: 0;
  }
`;

export const Card = styled.div`
  &.card {
    background-color: white;
    box-shadow: rgb(61 62 64 / 20%) 0px 3px 9px 0px;
    color: #3f3f40;
    text-align: center;
    padding: 15px;
    min-height: 300px;

    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-template-rows: 80px 80px;

    i,
    img {
      height: 64px;
      width: 64px;
      font-size: 64px;
      line-height: 64px;
      color: #01cfb3;
      align-self: center;
      flex-grow: 1;
    }
    h3 {
      font-size: 1.5em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
      flex-grow: 1;
      margin: 0;
      align-self: center;
    }

    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;

      font-size: 0.875rem;
    }
  }
`;

export const Content = styled.div`
  &.content {
    background-color: white;
    box-shadow: rgb(61 62 64 / 20%) 0px 3px 9px 0px;
    text-align: center;
    display: grid;
    @media (max-width: 992px) {
      display: block;
    }

    grid-template-columns: 1fr 2fr;
    color: #959aad;

    .menu {
      display: grid;
      grid-template-rows: repeat(auto-fill, minmax(80px, 1fr));
      .step {
        display: flex;
        align-items: center;
        height: 80px;
        padding: 30px;
        border-radius: 3px;

        h3 {
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 0;
          margin-left: 30px;
          text-align: left;
        }

        .number {
          min-width: 30px;
          min-height: 30px;
          border: black 1px solid;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
        }

        h3,
        .number {
          color: #3f3f40;
        }
      }
    }
    .active .step {
      h3,
      .number {
        color: #01cfb3;
      }
      .number {
        border-color: #01cfb3;
      }
    }

    article {
      min-height: 400px;
      color: #3f3f40;
      padding: 15px;
      border-left: rgb(227, 228, 230) 1px solid;
      @media (max-width: 992px) {
        border-left: 0;
        border-top: rgb(227, 228, 230) 1px solid;
        img {
          max-width: 100%;
        }
      }
      text-align: left;
      h1 {
        text-align: center;
      }
    }

    a {
      color: #01cfb3;
      text-decoration: none;
    }
  }
`;

export const AutoComplete = styled.ul`
  &.autocomplete {
    position: absolute;
    z-index: 2;
    background-color: white;
    width: 100%;
    border: 0.125rem #cacbcc solid;
    top: 38px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding-left: 10px;

      &:hover {
        background-color: #f2f4f5;
      }
      &:not(:last-child) {
        border-bottom: 1px #cacbcc solid;
      }
    }
    a {
      text-decoration: none !important;
      color: black;
    }
    h5 {
      padding-top: 4px;
    }
    p {
      margin: 0;
    }
  }
`;

import {
  put,
  takeLatest,
  StrictEffect,
  select,
  call,
} from "redux-saga/effects";
import { PayloadAction } from "typesafe-actions";
import api, { CustomGenerator } from "../api";
import ApplicationState from "../appStateInterface";
import {
  setSelectedByPathnameFailure,
  setSelectedByPathnameSuccess,
} from "./actions";
import { HelpCenterState, HelpCenterTypes } from "./types";
import mockJSON from "./data.json";

export function* load({
  payload,
}: PayloadAction<
  HelpCenterTypes.SET_SELECTED,
  { pathname: string }
>): Generator {
  try {
    const state = yield select();
    const { hasData } = (state as ApplicationState).helpCenter;

    if (hasData) yield put(setSelectedByPathnameSuccess(payload.pathname));
    else {
      // const resp = yield call(() =>
      //   api.get<HelpCenterState>(
      //     "https://ltm-ragnarok-main.s3.amazonaws.com/helpmarketplace/data.json"
      //   )
      // );
      // console.log(resp);
      // yield put(
      //   setSelectedByPathnameSuccess(
      //     payload.pathname,
      //     (resp as HelpCenterState).data
      //   )
      // );
      yield put(setSelectedByPathnameSuccess(payload.pathname, mockJSON.data));
    }
  } catch (err) {
    yield put(setSelectedByPathnameFailure());
  }
}

function* todoSaga(): Generator<StrictEffect> {
  yield takeLatest(HelpCenterTypes.SET_SELECTED, load);
}
export default todoSaga;

import React from "react";
import { Container, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import ApplicationState from "../../../store/appStateInterface";
import { HelpCenterCard } from "../../../store/helpcenter/types";
import { Content } from "./styled";

interface ContentContainerProps {
  parentCard: HelpCenterCard;
}
const ContentContainer: React.FunctionComponent<ContentContainerProps> = ({
  parentCard,
}: ContentContainerProps) => {
  const path = window.location.pathname.substring(
    0,
    window.location.pathname.indexOf(parentCard.slug) + parentCard.slug.length
  );
  const { selectedContent } = useSelector(
    (state: ApplicationState) => state.helpCenter
  );
  let content;
  if (parentCard.content) {
    content =
      parentCard?.content?.find((c) => c.slug === selectedContent) ||
      parentCard.content[0];
  }
  return (
    <Container>
      <h1>{parentCard.title}</h1>
      <Content className="content">
        <div className="menu">
          {parentCard.content?.map((c, index) => (
            <Nav.Link
              href={`${path}/${c.slug}`}
              key={c.slug}
              disabled={selectedContent === c.slug}
              className={selectedContent === c.slug ? "active" : ""}
            >
              <div className="step" key={c.slug}>
                <div className="number">{index + 1}</div>
                <div>
                  <h3>{c.title}</h3>
                </div>
              </div>
            </Nav.Link>
          ))}
        </div>
        {content && (
          <article
            className="body"
            dangerouslySetInnerHTML={{ __html: content.html }}
          />
        )}
      </Content>
    </Container>
  );
};

export default ContentContainer;
